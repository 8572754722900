import { useEffect } from "react";
import Header from "./header";
import { Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Layout = () => {
  const location = useLocation(); // 현재 경로 가져오기

  useEffect(() => {
    // 페이지 이동 시 스크롤을 맨 위로 이동
    window.scrollTo(0, 0);
  }, [location.pathname]); // 경로 변경될 때 실행

  const pageTitle = location.pathname.replace("/", "") || "Home";

  return (
    <div>
      <Helmet>
        <title>주식회사 하데스 Hades | {pageTitle}</title>
        <meta name="title" content="주식회사 하데스"></meta>
        <meta
          name="description"
          content="행복한 개발자들이 모이는 곳, 바로 하데스입니다. 상상하던 꿈을 현실로 초대한 프로젝트를 확인하세요."
        ></meta>
        <meta name="robots" content="all"></meta>

        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
